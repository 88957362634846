import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import {
	CssBaseline,
	makeStyles,
	Paper,
	Box,
	Grid,
	AppBar,
	Toolbar,
	Typography,
	Select,
	MenuItem,
	Button
} from '@material-ui/core';
import CropFreeIcon from '@material-ui/icons/CropFree';

import { products } from './data/products';

const useStyles = makeStyles(theme => ({
	root: {
		height: '100vh',
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 30
	},
	appBar: {
		width: '100%',
		maxWidth: 700,
		boxShadow: 'none'
	},
	paper: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		width: '100%',
		maxWidth: 700,
		minHeight: 400,
		padding: 30,
		[theme.breakpoints.up('sm')]: {
			padding: 50,
			minHeight: 450
		}
	},
	select: {
		height: 42
	},
	qrCodeResultWrap: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	qrCodeResult: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: 190,
		height: 190,
		marginTop: 10,
		background: '#f7f6f6',
		[theme.breakpoints.up('sm')]: {
			width: 230,
			height: 230,
			marginTop: 30
		}
	}
}));

function App() {
	const classes = useStyles();
	const [productName, setProductName] = React.useState(products[0].name);
	const [qrValue, setQrValue] = useState('');

	const handleChange = event => {
		setProductName(event.target.value);
	};

	const handleGenerate = () => {
		setQrValue(JSON.stringify(products.filter(product => product.name === productName)[0]));
	};

	const qrCodeResult = qrValue ? (
		<QRCode value={qrValue} size={230} level={'H'} renderAs={'svg'} />
	) : (
		<CropFreeIcon color="disabled" fontSize="large" />
	);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<Box>
				<AppBar position="relative" className={classes.appBar}>
					<Toolbar>
						<Typography variant="h6">Choose product</Typography>
					</Toolbar>
				</AppBar>

				<Paper className={classes.paper}>
					<Grid container spacing={3}>
						<Grid item sm={8} xs={12}>
							<Select value={productName} className={classes.select} fullWidth onChange={handleChange}>
								{products.map((product, index) => (
									<MenuItem key={index} value={product.name}>
										{product.name}
									</MenuItem>
								))}
							</Select>
						</Grid>

						<Grid item sm={4} xs={12}>
							<Button variant="contained" color="primary" size="large" fullWidth onClick={handleGenerate}>
								Generate
							</Button>
						</Grid>

						<Grid item xs={12} className={classes.qrCodeResultWrap}>
							<Paper elevation={0} className={classes.qrCodeResult}>
								{qrCodeResult}
							</Paper>
						</Grid>
					</Grid>
				</Paper>
			</Box>
		</div>
	);
}

export default App;
