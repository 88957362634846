export const products = [
	{
		name: '1 PET bottle passed(0,5L)',
		points: 5,
		address: 'Haggerston park.',
		type: 'PET',
		qty: 1
	},
	{
		name: '3 PET bottles passed(1,5L)',
		points: 45,
		address: 'Haggerston park.',
		type: 'PET',
		qty: 3
	},
	{
		name: '5 PET bottles passed(1L)',
		points: 50,
		address: 'Haggerston park.',
		type: 'PET',
		qty: 5
	},
	{
		name: '1 aluminum can passed(0.33L)',
		points: 3,
		address: 'Pownall Rd.',
		type: 'ALM',
		qty: 1
	},
	{
		name: '4 aluminum cans passed(0.33L)',
		points: 12,
		address: 'Pownall Rd.',
		type: 'ALM',
		qty: 4
	},
	{
		name: '6 aluminum cans passed(0.33L)',
		points: 18,
		address: 'Pownall Rd.',
		type: 'ALM',
		qty: 6
	},
	{
		name: 'T-shirt delivered-(first time)',
		points: 15,
		address: 'H & M store,25 Argyll street.',
		type: 'CLS',
		qty: 1
	},
	{
		name: 'jeans delivered-(second time)',
		points: 15,
		address: 'H & M store,25 Argyll street.',
		type: 'CLS',
		qty: 1
	},
	{
		name: 'shoes delivered-(third time)',
		points: 15,
		address: 'H & M store,25 Argyll street.',
		type: 'CLS',
		qty: 1
	}
];
